<template>
  <div class="congratulations-container bg-white h-screen relative text-black">
    <!-- FIXED ELEMENTS -->
    <div class="fixed-content">
      <img class="fixed z-20 logo mx-auto py-8 left-1/2 transform -translate-x-1/2 cursor-pointer" src="../assets/logos/louisxiii-black.svg" @click="exitUrl(7)" alt="">
      <div class="fixed z-20 share top-10 right-6 md:right-24 cursor-pointer md:hidden" @click="scrollToShare()">
        <img src="../assets/icons/share-black.svg" alt="">
      </div>
    </div>
    <!-- CONGRATULATIONS TEXT -->
    <div class="congratulations-text relative h-screen text-lg md:text-xl lg:text-xxl leading-tight px-8 md:px-32">
      <div class="congratulations-text-wrapper opacity-0 flex flex-col justify-center h-screen">
        <div class="transform -translate-y-10 md:translate-y-0">
          <p class="mb-6 md:mb-10"><span class="text-primary font-sangbleukingdom">Congratulations {{firstname}},</span><br class="md:hidden">&nbsp;you have just unraveled LOUIS XIII Mysteries.</p>
          <p>You’re now signed up to the draw to live an <span class="text-primary font-sangbleukingdom">unforgettable LOUIS XIII experience for two.</span></p>
        </div>
        <div class="absolute z-10 down-arrow bottom-14 left-1/2 transform -translate-x-1/2 cursor-pointer" @click="scrollToShare()">
          <img src="../assets/icons/down-arrow-brown.svg" alt="">
        </div>
      </div>
    </div>
    <!-- SHARE & DISCOVER PART -->
    <div id="share" class="bg-white flex flex-col lg:flex-row">
      <div class="flex-1">
        <img class="lg:hidden mx-auto" src="../assets/bg/reveal-tasting-min.jpg" alt="">
        <img class="hidden lg:block" src="../assets/bg/reveal-tasting.jpg" alt="">
      </div>
      <div class="flex flex-1 flex-col justify-center items-center text-center py-20">
        <div class="mb-10">
          <share-experience theme="light"/>
        </div>
        <div>
          <NCta
            borderRadius="0px"
            :backgroundColor="{default: colorStyle.primary}"
            :textColor="{default: 'white'}"
            type="a"
            @click.native="exitUrl(1)"
          >DISCOVER</NCta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "@/smoothScrollPolyfill"
import { mapGetters, mapActions } from "vuex";
import { isProdEnv, getDeviceType } from "../helpers";
import design from '@/validators/design'
import ShareExperience from '@/components/ShareExperience.vue'

export default {
  name: "Congratulations",
  components: {  
    ShareExperience
  },
  data() {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      typeOfDevice: getDeviceType(),
    };
  },
  methods: {
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      if (linknumber === 1) {
        this.$gtmTracking.discoverSuccess()
      }
      if (linknumber === 7) {
        this.$gtmTracking.logoRedirect()
      }
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    scrollToShare() {
      console.log('scroll')
      if (window.innerWidth < 768) {
        document.getElementById('share').scrollIntoView({behavior: "smooth"})
      } else {
        gsap.to(window, {duration: 1, scrollTo:{y:"#share"}}) // Because it didn't work on josa's computer. Don't know why
      }
    }
  },
  computed: {
    ...mapGetters({
      token: "global/token",
      idu: "user/idu",
      firstname: "user/firstname",
      visitId: "global/visitId",
      appConfig: "config/appConfig"
    }),
  },
  mounted() {
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
    gsap.registerPlugin(ScrollToPlugin);
    let tlContainer = gsap.timeline()
    tlContainer.to('.congratulations-text-wrapper', {delay:0.5, duration: 0.5, opacity: 1, y:-20})

    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "/js/smoothScrollPolyfill.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>

<style lang="scss" scoped>
.congratulations-container {
  display: initial;
  .fixed-content {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 100%);
  }
  .congratulations-text {
    background: url('../assets/bg/reveal-min.jpg');
    background-size: cover;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/reveal.jpg');
      background-size: cover;
    }  
  }
}
::v-deep .n-cta {
  @apply mt-6;
  width: 236px;
  .text {
    font-size: 14px;
  }
}
</style>
